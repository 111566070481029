import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import WindowStore from '~/stores/WindowStore';

const MOBILE_BREAKPOINT = 841;

type State = Readonly<{
	navExpanded: boolean;
	chatExpanded: boolean;
	enabled: boolean;
}>;

const initialState: State = {
	navExpanded: true,
	chatExpanded: false,
	enabled: false,
} as const;

class MobileLayoutStore extends Store<State> {
	constructor() {
		super(initialState);
		this.syncWith([WindowStore], () => this.handleWindowSizeChange());
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MOBILE_LAYOUT_STATE_UPDATE':
				return this.handleMobileLayoutStateUpdate(action);
			default:
				return false;
		}
	}

	private handleWindowSizeChange(): boolean {
		const {windowSize} = WindowStore.getState();

		this.setState((prevState) => {
			const newEnabled = windowSize.width < MOBILE_BREAKPOINT;

			if (newEnabled === prevState.enabled) {
				return prevState;
			}

			return {
				...prevState,
				enabled: newEnabled,
			};
		});

		return true;
	}

	private handleMobileLayoutStateUpdate({
		navExpanded,
		chatExpanded,
	}: Readonly<Partial<Pick<State, 'navExpanded' | 'chatExpanded'>>>): boolean {
		this.setState((prevState) => {
			const hasChanges =
				(navExpanded !== undefined && navExpanded !== prevState.navExpanded) ||
				(chatExpanded !== undefined && chatExpanded !== prevState.chatExpanded);

			if (!hasChanges) {
				return prevState;
			}

			return {
				...prevState,
				navExpanded: navExpanded ?? prevState.navExpanded,
				chatExpanded: chatExpanded ?? prevState.chatExpanded,
			};
		});

		return true;
	}

	isMobileLayout(): boolean {
		return this.state.enabled;
	}

	isNavExpanded(): boolean {
		return this.state.navExpanded;
	}

	isChatExpanded(): boolean {
		return this.state.chatExpanded;
	}
}

export default new MobileLayoutStore();
