import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type WindowSize = Readonly<{
	width: number;
	height: number;
}>;

type State = Readonly<{
	focused: boolean;
	windowSize: WindowSize;
}>;

const getWindowSize = (): WindowSize =>
	Object.freeze({
		width: window.innerWidth,
		height: window.innerHeight,
	});

const initialState: State = Object.freeze({
	focused: true,
	windowSize: getWindowSize(),
});

class WindowStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'WINDOW_FOCUS':
				return this.handleWindowFocus(action);
			case 'WINDOW_RESIZED':
				return this.handleWindowResized();
			default:
				return false;
		}
	}

	isFocused(): boolean {
		return this.state.focused;
	}

	getWindowSize(): WindowSize {
		return this.state.windowSize;
	}

	useWindowSize(): WindowSize {
		const {windowSize} = this.useStore();
		return windowSize;
	}

	useFocused(): boolean {
		const {focused} = this.useStore();
		return focused;
	}

	private handleWindowFocus({focused}: {focused: boolean}): boolean {
		this.setState((prevState) =>
			Object.freeze({
				...prevState,
				focused,
			}),
		);
		return true;
	}

	private handleWindowResized(): boolean {
		this.setState((prevState) =>
			Object.freeze({
				...prevState,
				windowSize: getWindowSize(),
			}),
		);
		return true;
	}
}

export default new WindowStore();
